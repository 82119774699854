var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SwContainer',_vm._b({},'SwContainer',{
        paddingY: _vm.paddingY,
        bgColor: _vm.bgColor,
        bgImage: _vm.bgImage,
        bgOpacity: _vm.bgOpacity,
    },false),[_c('div',{class:( _obj = {}, _obj[_vm.$style['block']] = true, _obj[_vm.$style['block_inverted']] = _vm.inverted, _obj[_vm.$style['block_reversed']] = _vm.reversed, _obj )},[_c('div',{class:_vm.$style['block__body']},[_c('h1',{class:[_vm.$style['block__title'], 'sw-title'],domProps:{"innerHTML":_vm._s(_vm.title)}}),_vm._v(" "),_c('div',{class:[_vm.$style['block__text'], 'sw-text'],domProps:{"innerHTML":_vm._s(_vm.text)}}),_vm._v(" "),(_vm.noIndex)?[_c('client-only',[_c('nofollow',[_c('noindex',[(_vm.buttonUrl.includes('//'))?_c('SwButton',{attrs:{"href":_vm.buttonUrl,"target":"_blank"}},[_vm._v("\n                                "+_vm._s(_vm.buttonLabel)+"\n                            ")]):_c('SwButton',{attrs:{"to":_vm.buttonUrl}},[_vm._v("\n                                "+_vm._s(_vm.buttonLabel)+"\n                            ")])],1)],1)],1)]:[(_vm.buttonUrl.includes('//'))?_c('SwButton',{attrs:{"href":_vm.buttonUrl,"target":"_blank"}},[_vm._v("\n                    "+_vm._s(_vm.buttonLabel)+"\n                ")]):_c('SwButton',{attrs:{"to":_vm.buttonUrl}},[_vm._v("\n                    "+_vm._s(_vm.buttonLabel)+"\n                ")])]],2),_vm._v(" "),_c('FeedbackForm',{class:_vm.$style['block__form'],attrs:{"pivot-id":_vm.pivotId,"title":_vm.formTitle,"text":_vm.formText,"fields":_vm.formFields,"button-text":_vm.formButtonLabel,"button-text-after-submit":_vm.formButtonAfterSubmit}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }